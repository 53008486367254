import { DateTime } from 'luxon';
import { createImageWidths } from 'mm-ui-components/dist/src/base/partials/image/image.utils';
import { TEAM_ID } from 'mm-ui-components';
import { Data } from '../initialStateRegistration';
import { mandatory, nonMandatory } from '../storeFromRequestParamsValidation';
import { createArticles, createImage, createLocaleData, createTimeZoneData } from '../articles.utils';
import {
  CommercialSection,
  FeedPageMinorSectionWithoutTitle,
  FeedPageMinorSectionWithTitle,
  HomePageMinorSection,
  ImageTopSection,
  TopSectionWithoutSubtitle,
  TopSectionWithSubtitle,
  SectionWithArticlesOnly,
  HreflangItem,
  Pagination,
  NetworkLinkData,
  NetworkLink,
  HomePageImageLinksSection,
  HomePageBannerSection,
  HomePageWithBannerAndButton,
  SearchPageExpandableSection,
  TitleAndSubtitle,
  GridSection,
  HomePageWithAdditionalLinksSection,
  HomePageBannerWithLinkSection,
  TopSectionWithFeaturedArticle,
  SectionWithArticlesAndImage,
  ExpandableSection,
  SportradarProps,
} from './template.state.types';
import { createBaseLinkInitialState, createLinkSvg, createSvg } from '../navigations/navigation.utils';
import { SPORTS, TICKER_LEAGUES } from '../../templates/templates.const';

export const createSectionWithArticlesOnlyState = (sectionName: string) => (data: Data): SectionWithArticlesOnly => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');
  return {
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
  };
};

export const createSectionWithArticlesAndImage = (sectionName: string) => (data: Data): SectionWithArticlesAndImage => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');
  return {
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    image: mandatory(data.pageContent[sectionName].image.value, {}, createImage(imagesHost)),
  };
};

export const createSearchPageExpandableSection = (sectionName: string) => (data: Data): SearchPageExpandableSection => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');
  return {
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    seeMorePaginationURL: nonMandatory(data.pageContent[sectionName].feed.value.pagination.next),
  };
};


export const createTopSectionWithoutSubtitleState = (sectionName: string) => (data: Data): TopSectionWithoutSubtitle => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');
  return {
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    sectionTitle: mandatory(data.pageContent[sectionName].title.value, ''),
  };
};

export const createTopSectionWithFeaturedArticle = (sectionName: string) => (data: Data): TopSectionWithFeaturedArticle => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');
  return {
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    sectionTitle: mandatory(data.pageContent[sectionName].title.value, ''),
    featuredArticle: nonMandatory(data.pageContent[sectionName].featuredArticle.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
  };
};

export const createTopSectionWitSubtitleState = (sectionName: string) => (data: Data): TopSectionWithSubtitle => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');
  return {
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    sectionTitle: mandatory(data.pageContent[sectionName].title.value, ''),
    sectionSubtitle: nonMandatory(data.pageContent[sectionName].subtitle.value),
  };
};

export const createImageTopSectionState = (sectionName: string) => (data: Data): ImageTopSection => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  return {
    sectionTitle: mandatory(data.pageContent[sectionName].title.value, ''),
    sectionSubtitle: nonMandatory(data.pageContent[sectionName].subtitle.value),
    image: mandatory(data.pageContent[sectionName].background.value, {}, createImage(imagesHost)),
  };
};


const combineTwoArticleSections = (feedSection1Articles: Array<any>, feedSection2Articles: Array<any>) => {
  if (feedSection1Articles && feedSection2Articles) {
    return [...feedSection1Articles, ...feedSection2Articles];
  }

  if (feedSection1Articles) {
    return feedSection1Articles;
  }

  if (feedSection2Articles) {
    return feedSection2Articles;
  }

  return null;
};

export const combineTwoSectionsAndCreateFeedPageMinorSectionWithTitleState = (feedSection1Name: string, feedSection2Name: string) => (data: Data): FeedPageMinorSectionWithTitle => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');

  const feedSection1Articles = nonMandatory(data.pageContent[feedSection1Name].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint));
  const feedSection2Articles = nonMandatory(data.pageContent[feedSection2Name].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint));

  return {
    articles: combineTwoArticleSections(feedSection1Articles, feedSection2Articles),
    sectionTitle: mandatory(data.pageContent[feedSection1Name].title.value, ''),
    showMorePaginationText: nonMandatory(data.pageContent[feedSection2Name].showMoreText.value),
    showMorePaginationURL: nonMandatory(data.pageContent[feedSection2Name].feed.value.pagination.next),
  };
};

export const createFeedPageMinorSectionWithTitleState = (sectionName: string) => (data: Data): FeedPageMinorSectionWithTitle => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');

  return {
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    sectionTitle: mandatory(data.pageContent[sectionName].title.value, ''),
    showMorePaginationText: nonMandatory(data.pageContent[sectionName].showMoreText.value),
    showMorePaginationURL: nonMandatory(data.pageContent[sectionName].feed.value.pagination.next),
  };
};

export const createFeedPageMinorSectionWithoutTitleState = (sectionName: string) => (data: Data): FeedPageMinorSectionWithoutTitle => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');

  return {
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    showMorePaginationText: nonMandatory(data.pageContent[sectionName].showMoreText.value),
    showMorePaginationURL: nonMandatory(data.pageContent[sectionName].feed.value.pagination.next),
  };
};

export const createHomePageWithBannerAndButton = (sectionName: string) => (data: Data): HomePageWithBannerAndButton => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');
  const aspectRatio = nonMandatory(data.pageContent[sectionName].image.value.aspectRatio);

  return {
    image: nonMandatory(data.pageContent[sectionName].image.value, createImage(imagesHost, aspectRatio)),
    sectionTitle: mandatory(data.pageContent[sectionName].title.value, ''),
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    showMoreText: nonMandatory(data.pageContent[sectionName].moreButton.value.text),
    showMoreURL: nonMandatory(data.pageContent[sectionName].moreButton.value.url),
    link: nonMandatory(data.pageContent[sectionName].link.value.url),
    featuredArticle: nonMandatory(data.pageContent[sectionName].featuredArticle.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
  };
};

export const createHomePageMinorSectionState = (sectionName: string) => (data: Data): HomePageMinorSection => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const moreButtonType = nonMandatory(data.pageContent[sectionName].moreButton.type);
  const editionEndpoint = mandatory(data.editionEndpoint, '');

  return {
    articles: mandatory(data.pageContent[sectionName].feed.value.articles, [], createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    sectionTitle: mandatory(data.pageContent[sectionName].title.value, ''),
    moreButtonText: moreButtonType === 'link' ? nonMandatory(data.pageContent[sectionName].moreButton.value, createBaseLinkInitialState) : null,
    moreButtonSvg: moreButtonType === 'linkSvg' ? nonMandatory(data.pageContent[sectionName].moreButton.value, createLinkSvg) : null,
  };
};

const formattedImageLinks = (host: string) => (imageLinks: any) => imageLinks.map((image: any) => {
  const optionalText = nonMandatory(image.text);

  return {
    imageWidths: createImageWidths(540, 540, 540),
    host,
    path: image.src,
    alt: image.alt,
    linkSrc: image.url,
    ...(optionalText && { text: optionalText }),
  };
});

export const createHomePageImageLinksSectionState = (sectionName: string) => (data: Data): HomePageImageLinksSection => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const moreButtonType = nonMandatory(data.pageContent[sectionName].moreButton.type);

  return {
    imageLinks: mandatory(data.pageContent[sectionName].imageLinks.value, [], formattedImageLinks(imagesHost)),
    sectionTitle: mandatory(data.pageContent[sectionName].title.value, ''),
    moreButtonText: moreButtonType === 'link' ? nonMandatory(data.pageContent[sectionName].moreButton.value, createBaseLinkInitialState) : null,
    moreButtonSvg: moreButtonType === 'linkSvg' ? nonMandatory(data.pageContent[sectionName].moreButton.value, createLinkSvg) : null,
  };
};

export const createExpandableSectionState = (sectionName: string) => (data: Data): ExpandableSection => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');
  const moreButtonType = nonMandatory(data.pageContent[sectionName].moreButton.type);

  return {
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    sectionTitle: mandatory(data.pageContent[sectionName].title.value, ''),
    showMorePaginationText: nonMandatory(data.pageContent[sectionName].showMoreText.value),
    showMorePaginationURL: nonMandatory(data.pageContent[sectionName].feed.value.pagination.next),
    moreButtonText: moreButtonType === 'link' ? nonMandatory(data.pageContent[sectionName].moreButton.value, createBaseLinkInitialState) : null,
  };
};

export const createCommercialSectionState = (sectionName: string) => (data: Data): CommercialSection => {
  const imagesCDNHost = mandatory(data.propertySettings.imagesCDNHost, '');
  return {
    logo: mandatory(data.pageContent[sectionName].logo.value, {}, createSvg),
    background: mandatory(data.pageContent[sectionName].background.value, {}, createImage(imagesCDNHost)),
    sectionTitle: mandatory(data.pageContent[sectionName].title.value, ''),
    subtitle: mandatory(data.pageContent[sectionName].subtitle.value, ''),
    link: mandatory(data.pageContent[sectionName].link.value, ''),
  };
};

export const createHreflangData = (data: any[]): HreflangItem[] => {
  return data.map((linkItem: any) => ({ langCode: linkItem.name, url: linkItem.url }));
};

export const createPagination = (paginationData: any): Pagination => ({
  prevButtonText: mandatory(paginationData.previousButton.value.text, ''),
  prevButtonURL: nonMandatory(paginationData.previousButton.value.url),
  nextButtonText: mandatory(paginationData.nextButton.value.text, ''),
  nextButtonURL: nonMandatory(paginationData.nextButton.value.url),
  currentPage: nonMandatory(paginationData.currentPage) || 1,
});

export const createNetworkLinkData = (networkLink: NetworkLinkData) => {
  const res: NetworkLink = {
    siteName: nonMandatory(networkLink.siteName.value),
    sitePrimaryColor: nonMandatory(networkLink.sitePrimaryColor.value),
    siteSecondaryColor: nonMandatory(networkLink.siteSecondaryColor.value),
    siteURL: nonMandatory(networkLink.siteURL.value),
    teamName: nonMandatory(networkLink.teamName.value),
  };
  const areAllFieldsExist = !!(res.siteName && res.sitePrimaryColor && res.siteSecondaryColor && res.siteURL && res.teamName);
  return areAllFieldsExist ? res : null;
};

export const createHomePageWithBanner = (sectionName: string) => (data: Data): HomePageBannerSection => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const aspectRatio = nonMandatory(data.pageContent[sectionName].image.value.aspectRatio);

  return {
    image: nonMandatory(data.pageContent[sectionName].image.value, createImage(imagesHost, aspectRatio)),
  };
};

export const createTitleAndSubTitle = (sectionName: string) => (data: Data): TitleAndSubtitle => {
  return {
    sectionTitle: nonMandatory(data.pageContent[sectionName].title.value),
    sectionSubtitle: nonMandatory(data.pageContent[sectionName].subtitle.value),
  };
};

export const createGridSectionState = (sectionName: string) => (data: Data): GridSection => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const locale = createLocaleData(data);
  const timeZone = createTimeZoneData(data);
  const midnightInTimeZone = DateTime.local().setZone(timeZone).startOf('day').toISO();
  const editionEndpoint = mandatory(data.editionEndpoint, '');

  return {
    articles: nonMandatory(data.pageContent[sectionName].feed.value.articles, createArticles(imagesHost, midnightInTimeZone, timeZone, locale, editionEndpoint)),
    sectionTitle: nonMandatory(data.pageContent[sectionName].title.value),
    sectionTitleLogo: nonMandatory(data.pageContent[sectionName].logo.value, createLinkSvg),
  };
};

export const createSportradarProps = (data: any): SportradarProps => {
  const league = nonMandatory(data.pageContent.sportradarProps.league.value);
  const teamUid = nonMandatory(data.pageContent.sportradarProps.teamUid.value);
  const soccerLeague = nonMandatory(data.pageContent.sportradarProps.soccerLeague.value);
  const matchId = nonMandatory(data.pageContent.sportradarProps.matchId.value);
  const seasonId = nonMandatory(data.pageContent.sportradarProps.seasonId.value);

  return {
    ...(league && { league }),
    ...(soccerLeague && { soccerLeague }),
    ...(teamUid && { teamUid }),
    ...(matchId && { matchId }),
    ...(seasonId && { seasonId }),
  };
};

const convertToNumber = (id: string | number) => {
  return typeof id === 'string' ? parseInt(id, 10) : id;
};

export const createMatchProps = (data: any): SportradarProps => {
  const usSport = mandatory(data.usSport, '', convertToNumber);
  const league = mandatory(data.sportId, '', (sport: string) => {
    const sportId = convertToNumber(sport);
    if (sportId === 2 && usSport === 8) {
      return SPORTS.WNBA;
    }
    if (usSport === 6) {
      return SPORTS.NCAAF;
    }

    return TICKER_LEAGUES[sportId];
  });

  let teamAId = mandatory(data.homeTeamUid, '');
  teamAId = league !== SPORTS.NFL ? convertToNumber(teamAId) : teamAId;
  let teamBId = mandatory(data.awayTeamUid, '');
  teamBId = league !== SPORTS.NFL ? convertToNumber(teamBId) : teamBId;
  let matchId = mandatory(data.matchId, '');
  matchId = league !== SPORTS.NFL ? convertToNumber(matchId) : matchId;

  const findTeamName = (team: string | number) => {
    const teams = TEAM_ID[league];
    if (!teams) return '';

    return Object.keys(teams).map(teamName => {
      if (teams[teamName] === team && !league.includes(SPORTS.NCAA)) {
        const teamArr = teamName.split('-');
        return teamArr.map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(' ');
      }

      return '';
    }).filter(Boolean)[0] || '';
  };

  return {
    league,
    teamAId,
    teamA: findTeamName(teamAId) || SPORTS.HOME,
    teamBId,
    teamB: findTeamName(teamBId) || SPORTS.AWAY,
    seasonId: mandatory(data.seasonId, '', convertToNumber),
    matchId,
    usSport,
    matchStatus: mandatory(data.matchStatus, ''),
    tab: nonMandatory(data.tab) || SPORTS.GAME,
    srMatchId: nonMandatory(data.srMatchId, convertToNumber),
  };
};

export const createHomePageWithBannerAndLink = (sectionName: string) => (data: Data): HomePageBannerWithLinkSection => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const aspectRatio = nonMandatory(data.pageContent[sectionName].image.value.aspectRatio);

  return {
    image: nonMandatory(data.pageContent[sectionName].image.value, createImage(imagesHost, aspectRatio)),
    link: nonMandatory(data.pageContent[sectionName].link.value.url),
  };
};

export const createImageWithAdditionaLinks = (imagesHost: any, additionalLinks: any) => (images: Array<any>) => {
  const formattedImages = formattedImageLinks(imagesHost)(images);
  return formattedImages.map((image: any, index: number) => {
    return {
      ...image,
      title: mandatory(image.text, ''),
      imageLink: mandatory(image.linkSrc, ''),
      links: nonMandatory([{ text: additionalLinks[index].name, link: additionalLinks[index].url }]),
    };
  });
};

export const createTopSectionWithAdditonalLinksState = (sectionName: string) => (data: Data): HomePageWithAdditionalLinksSection => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const additionalLinks = nonMandatory(data.pageContent[sectionName].additionalLinks.value);
  return {
    imagesWithAdditoinalLinks: nonMandatory(data.pageContent[sectionName].imageLinks.value, createImageWithAdditionaLinks(imagesHost, additionalLinks)),
  };
};

export const createSIMetaImage = (canonical: string, pageImage: any) => {
  let metaImage = pageImage;

  if (canonical.match(/(www.si)/g) && pageImage?.path?.includes('dataimagejpegbase649j4AAQSkZJRgABAQAAAQABAAD2wBDAA-c73c466810fb33c2cd672fc1d55565c4')) {
    metaImage = {
      host: 'https://images2.minutemediacdn.com/image/upload/',
      path: 'v1712170891/SI/Cover%20images/SI_Generic_image.jpg',
      alt: 'Sports Illustrated',
    };
  }

  return metaImage;
};
